import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import { Link, graphql } from 'gatsby';
import HikakuTable from 'components/hikakuTableLoadable';
import PassMarkSearch from 'components/passMarkSearch';
import Accordion from 'components/accordion';
import Socials from 'components/socials';
import BenchMark from 'components/cpu/benchmark';
import CpuMatsubi from 'components/cpu/cpu_matsubi';
import HowtoFilter from 'components/cpu/howtofilter';
import TableDescription from 'components/cpu/table_description';
import PassMark from 'components/cpu/passmark';
import Image from "components/image";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "cpu"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/cpu/cpu-hikaku.png"}) { ...eyecatchImg },
    allCpuallJson {
      nodes {
        name
        pm
        pms
        year
        clock
        core
        thread
        tdp
        price
        cospa
      }
    },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`CPU性能比較表 | 最新から定番のCPUまで簡単に比較`}</h1>
    <time itemProp="datePublished" dateTime="Sat Aug 24 2024 01:01:07 GMT+0900 (日本標準時)">更新日:2024年8月24日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="CPU性能比較" mdxType="Image" />
    <p>{`本サイトでは定番及び最新CPUの性能を簡単に比較検討できるよう、信頼性の高いベンチマークであるPassMarkから総合スコア及びシングルスレッドスコアの情報を収集し、一覧として表にまとめている。`}</p>
    <p>{`同様に`}<Link to="/gpu/" mdxType="Link">{`GPU性能比較表`}</Link>{`もあるのでCPUではなくGPUを調べたい時は参考にして欲しい。`}</p>
    <BenchMark mdxType="BenchMark" />
    <CpuMatsubi mdxType="CpuMatsubi" />
    <TableDescription mdxType="TableDescription" />
    <HowtoFilter mdxType="HowtoFilter" />
    <p>{`PassMarkの点数の目安を主観ではあるが下記に示しておく。比較検討で役立つだろう。`}</p>
    <PassMark mdxType="PassMark" />
    <p>{`また、上記総合スコアである`}<em parentName="p">{`PassMark(M)`}</em>{`(Mはマルチコアの略)の他、シングルコアスコアである`}<em parentName="p">{`PassMark(S)`}</em>{`も併記する。ゲーム性能やサクサク感に影響する指標のため補足的に活用して欲しい。`}</p>
    <p>{`表に記載のないCPUは、前後の型番から推察するか、2000種類以上のベンチマーク結果が登録されている下記PassMarkSearchに型番を入力して確認してほしい。`}</p>
    <PassMarkSearch mdxType="PassMarkSearch" />
    <HikakuTable {...props} checkboxes={['corei', 'ryzen', 'celeron', 'notedesk', 'apple']} cpuData={props.data.allCpuallJson.nodes} mdxType="HikakuTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      